import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Dashboard from "./Dashboard";
import Login from "./Login";
// import Register from "./Register";

const App = () => {
  const isAuthenticated = localStorage.getItem('token'); // Zameni sa pravom autentikacijom

  return (
    <Router>
      <Routes>
        {/* Public routes */}
        <Route path="/login" element={<Login />} />
        {/* <Route path="/register" element={<Register />} /> */}

        {/* Protected routes */}
        <Route
          path="/*"
          element={
            isAuthenticated ? (
              <Dashboard />
            ) : (
              <Navigate to="/login" replace />
            )
          }
        />
      </Routes>
    </Router>
  );
};

export default App;


// export const baseUrl = "http://localhost:8000";
export const baseUrl = "https://laravel-api.filipmiskovicguitar.com";